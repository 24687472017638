import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAnthropometryByPatientId } from '../../../redux/actions/anthropometryActions';
import { fetchPatients, fetchAllProfessionals } from '../../../redux/actions/relatedEntitiesActions';
import { fetchPersons } from '../../../redux/actions/personActions';
import { clearAnthropometry } from '../../../redux/slices/anthropometrySlice';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Pagination from '../../../components/Pagination/Pagination';
import { AuthContext } from '../../../context/auth';

import { FaUserDoctor } from "react-icons/fa6";
import { MdOutlineSick } from "react-icons/md";
import { LuCalendarClock } from "react-icons/lu";
import { BiBody } from "react-icons/bi";
import { MdHome } from 'react-icons/md';

import '../styles/GetAnthropometry.css';
import '../../../Mobile.css';

import Accordion from '../../../components/Accordion/Accordion';

function GetAnthropometryByPatientId() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientGender, setPatientGender] = useState('');
  const [patientBirthDate, setPatientBirthDate] = useState('');
  const [loadedProfessionals, setLoadedProfessionals] = useState({});
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const returnBtn = "Retornar";

  // Obtendo pacientes, profissionais e estado de antropometria
  const { patients = [], loadingPatients, errorPatients, professionals, loadingProfessionals, errorProfessionals } = useSelector((state) => state.relatedEntities);
  const { persons = [], loadingPersons, errorPersons } = useSelector((state) => state.persons);
  const { currentAnthropometry = [], loadingAnthropometry, errorAnthropometry } = useSelector((state) => state.anthropometry || { currentAnthropometry: [] });

  useEffect(() => {
    console.log('Dados de antropometria:', currentAnthropometry);
  }, [currentAnthropometry]);

  // Carregar pacientes, pessoas e profissionais ao montar o componente
  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchPersons());
    dispatch(fetchAllProfessionals());
    dispatch(clearAnthropometry());
  }, [dispatch]);

  // Adicionar logs para verificar os dados recebidos
  useEffect(() => {
    console.log('Persons:', persons);
    console.log('Professionals:', professionals);
  }, [persons, professionals]);

  // Atualizar o estado de loadedProfessionals quando persons ou professionals mudarem
  useEffect(() => {
    if (!loadingPersons && !loadingProfessionals && persons.length > 0 && professionals.length > 0) {
      const professionalMap = {};

      // Mapeia todos os profissionais com base no person_id
      // biome-ignore lint/complexity/noForEach: <explanation>
      persons.forEach(person => {
        if (['nutritionist', 'professional', 'doctor'].includes(person.person_type)) {
          professionalMap[person.person_id] = `${person.first_name} ${person.last_name}`;
        }
      });

      // Adiciona ou atualiza com informações adicionais dos professionals, se necessário
      // biome-ignore lint/complexity/noForEach: <explanation>
      professionals.forEach(professional => {
        if (!professionalMap[professional.person_id]) {
          professionalMap[professional.person_id] = professional.name || 'Nome não disponível';
        }
      });

      setLoadedProfessionals(professionalMap);
      console.log('Profissionais mapeados:', professionalMap);
    }
  }, [loadingPersons, loadingProfessionals, persons, professionals]);

  // Função para lidar com a seleção de paciente e atualizar gênero e data de nascimento
  const handlePatientChange = (selectedOption) => {
    setSelectedPatient(selectedOption);
    if (selectedOption) {
      const patientDetails = persons.find(person => person.person_id === selectedOption.value);
      if (patientDetails) {
        setPatientGender(patientDetails.gender);
        setPatientBirthDate(patientDetails.birth_date);
      }
      dispatch(fetchAnthropometryByPatientId(selectedOption.value));
    }
  };

  // Função para buscar o nome do nutricionista pelo id
  const findNutritionistName = (nutritionist_id) => {
    if (!nutritionist_id) return 'ID do nutricionista não fornecido';
    return loadedProfessionals[nutritionist_id] || 'Nutricionista não encontrado';
  };

  // Paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = (currentAnthropometry || []).slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/dashboard');
  }


  // const formatDate = (date) => {
  //   const d = new Date(date);
  //   return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${d.getFullYear()}`;
  // };

  const calculateAge = (patientBirthDate) => {
    const today = new Date();
    const birthDate = new Date(patientBirthDate);
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (days < 0) {
      months--;
      const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      days += lastMonth.getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    return `${pluralize(years, 'Ano', 'Anos')} ${pluralize(months, 'mês', 'meses')} e ${pluralize(days, 'dia', 'dias')}`;
  };

  const pluralize = (count, singular, plural) => {
    return `${count} ${count === 1 ? singular : plural}`;
  };

  const calculateAgeYear = (patientBirthDate) => {
    const today = new Date();
    const birthDate = new Date(patientBirthDate);
    let years = today.getFullYear() - birthDate.getFullYear();
    const months = today.getMonth() - birthDate.getMonth();
    const days = today.getDate() - birthDate.getDate();

    // Ajuste para meses e dias se o aniversário ainda não foi completado no ano corrente
    if (months < 0 || (months === 0 && days < 0)) {
      years--;
    }

    return years; // Retorna apenas os anos
  }

  const formatDate = (patientBirthDate) => {
    if (!patientBirthDate) return 'Data inválida';
    const date = new Date(patientBirthDate);
    if (Number.isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatIMC = (bmi_classification_code) => {
    switch (bmi_classification_code) {
      case 'underweight':
        return 'Subpeso';
      case 'normal':
        return 'Normal';
      case 'overweight':
        return 'Sobrepeso';
      case 'obese':
        return 'Obesidade';
      default:
        return '';
    }
  };

  const formatGender = (patientGender) => {
    switch (patientGender) {
      case 'male':
        return 'Masculino';
      case 'female':
        return 'Feminino';
      case 'others':
        return 'Outros';
      default:
        return '';
    }
  }


  const [imc, setImc] = useState(0);
  const [pesoTeorico, setPesoTeorico] = useState(0);
  const [classificacaoBernadinelli, setClassificacaoBernadinelli] = useState('');
  const [porcentagemPT, setPorcentagemPT] = useState(0);
  const [classificacaoBlackburn, setClassificacaoBlackburn] = useState('');
  const [quetelet, setQuetelet] = useState(0);
  const [imcClassificacao, setImcClassificacao] = useState('');
  const [cmb, setCmb] = useState(0);  // Circunferência Muscular do Braço
  const [amb, setAmb] = useState(0);  // Área Muscular do Braço
  const [ambc, setAmbc] = useState(0);  // Área Muscular do Braço Corrigida
  const [ab, setAb] = useState(0);    // Área do Braço
  const [agb, setAgb] = useState(0);   // Área Gordurosa do Braço
  const [classificacaoMusculatura, setClassificacaoMusculatura] = useState('');
  const [cintura, setCintura] = useState(0);  // Circunferência da Cintura
  const [classificacaoCintura, setClassificacaoCintura] = useState('');
  const [alturaEstimada, setAlturaEstimada] = useState(0);
  const [percentualGordura, setPercentualGordura] = useState(0);
  const [imcCriancasClassificacao, setImcCriancasClassificacao] = useState(0);
  const [imcIdososClassificacao, setImcIdososClassificacao] = useState('');
  const [dum, setDum] = useState(null);
  const [idadeGestacional, setIdadeGestacional] = useState(0);
  const [dpp, setDpp] = useState(0);



  // Verifique se a lista de currentAnthropometry não está vazia antes de acessar o primeiro elemento
  useEffect(() => {
    if (currentAnthropometry.length > 0) {
      const heightInCm = Number.parseFloat(currentAnthropometry[0].height);  // Altura em centímetros
      const heightInMeters = heightInCm / 100;  // Convertendo de centímetros para metros
      const currentWeight = Number.parseFloat(currentAnthropometry[0].current_weight);  // Peso atual
      const gender = patientGender;  // Gênero

      // Calcular IMC
      const calculatedImc = calcularIMC(heightInMeters, currentWeight);
      setImc(calculatedImc);
      setImcClassificacao(classificarIMC(calculatedImc));

      // Calcular quetelet
      const calculatedQuetelet = calcularquetelet(currentWeight, heightInMeters);
      setQuetelet(calculatedQuetelet);

      // Calcular Peso Teórico e classificação de Bernadinelli
      const calculatedPesoTeorico = calcularPesoTeorico(heightInCm, gender);
      setPesoTeorico(calculatedPesoTeorico);
      setClassificacaoBernadinelli(classificarBiotipo(heightInCm, gender));

      // Calcular % PA/PT (Percentual de Peso Atual sobre Peso Teórico)
      const calculatedPorcentagemPT = (currentWeight / calculatedPesoTeorico) * 100;
      setPorcentagemPT(calculatedPorcentagemPT);
      setClassificacaoBlackburn(classificarBlackburn(calculatedPorcentagemPT));

      // Cálculos baseados no Jelliffe (PCT, CB, CMB, AMB)
      const cb = Number.parseFloat(currentAnthropometry[0].arm_circumference);  // Circunferência do Braço (CB)
      const pct = Number.parseFloat(currentAnthropometry[0].triceps_skinfold);  // Dobra Cutânea Tríceps (PCT)

      setCmb(calcularCMB(cb, pct));
      setAmb(calcularAMB(cb, pct));
      setAmbc(calcularAMBc(cb, pct, gender));  // Chamando função para AMBc
      setAb(calcularAB(cb));  // Chamando função para AB
      setAgb(calcularAGB(calcularAB(cb), calcularAMB(cb, pct)));  // Chamando função para AGB
      setClassificacaoMusculatura(classificarMusculatura(calcularAMB(cb, pct), gender));

      // Circunferência da Cintura
      const waistCircumference = Number.parseFloat(currentAnthropometry[0].waist_circumference);
      setCintura(waistCircumference);
      setClassificacaoCintura(classificarCircunferenciaCintura(waistCircumference, gender));

      // Altura estimada
      const idade = calculateAgeYear(patientBirthDate);
      const alturaJoelho = Number.parseFloat(currentAnthropometry[0].knee_height);
      setAlturaEstimada(calcularAlturaEstimada(idade, alturaJoelho, gender));

      // Percentual de Gordura
      const somaDobras = currentAnthropometry[0].suprailiac_skinfold + currentAnthropometry[0].subscapular_skinfold;
      setPercentualGordura(calcularPercentualGordura(gender, somaDobras, idade, "Σ7"));

      // IMC para idosos e crianças
      setImcIdososClassificacao(classificarIMCIdosos(calculatedImc));
      const imcZscore = currentAnthropometry[0].imc_zscore;
      setImcCriancasClassificacao(classificarIMCCriancas(imcZscore));

      // Gestacional
      const dum = currentAnthropometry[0].last_period_date;
      setDum(dum);
      setIdadeGestacional(calcularIdadeGestacional(dum));
      setDpp(calcularDPP(dum));
    } else {
      console.error("Nenhuma antropometria encontrada.");
    }
  }, [currentAnthropometry, patientGender]);  // Adicione dependências relevantes

  const calcularMedidas = (anthropometry) => {
    const heightInCm = Number.parseFloat(anthropometry.height);
    const heightInMeters = heightInCm / 100;
    const currentWeight = Number.parseFloat(anthropometry.current_weight);
    const gender = patientGender;

    const imc = calcularIMC(heightInMeters, currentWeight);
    const imcClassificacao = classificarIMC(imc);
    const quetelet = calcularquetelet(currentWeight, heightInMeters);
    const pesoTeorico = calcularPesoTeorico(heightInCm, gender);
    const classificacaoBernadinelli = classificarBiotipo(heightInCm, gender);
    const porcentagemPT = (currentWeight / pesoTeorico) * 100;
    const classificacaoBlackburn = classificarBlackburn(porcentagemPT);
    const cb = Number.parseFloat(anthropometry.arm_circumference);
    const pct = Number.parseFloat(anthropometry.triceps_skinfold);
    const cmb = calcularCMB(cb, pct);
    const amb = calcularAMB(cb, pct);
    const ambc = calcularAMBc(cb, pct, gender);
    const ab = calcularAB(cb);
    const agb = calcularAGB(ab, amb);
    const classificacaoMusculatura = classificarMusculatura(amb, gender);

    return {
      imc,
      imcClassificacao,
      quetelet,
      pesoTeorico,
      classificacaoBernadinelli,
      porcentagemPT,
      classificacaoBlackburn,
      cmb,
      amb,
      ambc,
      ab,
      agb,
      classificacaoMusculatura
    };
  };

  // Função para calcular o IMC
  function calcularIMC(heightInMeters, currentWeight) {
    if (!heightInMeters || !currentWeight) {
      console.error("Altura ou peso não fornecidos corretamente");
      return Number.NaN;
    }
    const imc = currentWeight / (heightInMeters * heightInMeters);
    return imc.toFixed(2);
  }

  // Função para classificar o IMC de acordo com WHO 2004
  function classificarIMC(imc) {
    if (imc < 16.00) return "Magreza Grau III";
    if (imc >= 16.00 && imc <= 16.99) return "Magreza Grau II";
    if (imc >= 17.00 && imc <= 18.49) return "Magreza Grau I";
    if (imc >= 18.50 && imc <= 24.99) return "Eutrófico";
    if (imc >= 25.00 && imc <= 29.99) return "Pré-obeso";
    if (imc >= 30.00 && imc <= 34.99) return "Obesidade Grau I";
    if (imc >= 35.00 && imc <= 39.99) return "Obesidade Grau II";
    if (imc >= 40.00) return "Obesidade Grau III";
    return "Indeterminado";
  }

  // Função para calcular quetelet 
  function calcularquetelet(currentWeight, heightInMeters) {
    return (currentWeight / (heightInMeters * heightInMeters)).toFixed(2);
  }

  // Função para calcular o Peso Teórico com base no biotipo e altura
  function calcularPesoTeorico(heightInCm, gender) {
    const basePeso = heightInCm - 100;
    let porcentagem = 0;

    if (gender === 'male') {
      if (heightInCm >= 140 && heightInCm <= 155) {
        porcentagem = 0;  // Brevelíneo
      } else if (heightInCm > 155 && heightInCm <= 170) {
        porcentagem = 5;  // Normolíneo
      } else if (heightInCm > 170) {
        porcentagem = 10;  // Longelíneo
      }
    } else if (gender === 'female') {
      if (heightInCm >= 140 && heightInCm <= 150) {
        porcentagem = 0;  // Brevelínea
      } else if (heightInCm > 150 && heightInCm <= 165) {
        porcentagem = 10;  // Normolínea
      } else if (heightInCm > 165) {
        porcentagem = 15;  // Longelínea
      }
    }

    return basePeso - (basePeso * porcentagem / 100);  // PT = (Altura - 100) - % Biotipo
  }

  // Função para classificar o biotipo com base na altura e gênero
  function classificarBiotipo(heightInCm, gender) {
    if (gender === 'male') {
      if (heightInCm >= 140 && heightInCm <= 155) {
        return 'Brevelíneo';
      } if (heightInCm > 155 && heightInCm <= 170) {
        return 'Normolíneo';
      } if (heightInCm > 170) {
        return 'Longelíneo';
      }
    } else if (gender === 'female') {
      if (heightInCm >= 140 && heightInCm <= 150) {
        return 'Brevelínea';
      } if (heightInCm > 150 && heightInCm <= 165) {
        return 'Normolínea';
      } if (heightInCm > 165) {
        return 'Longelínea';
      }
    }
    return 'Indeterminado';
  }

  // Função para classificar com base na % do Peso Teórico (Tabela de Blackburn)
  function classificarBlackburn(porcentagemPT) {
    if (porcentagemPT >= 91) {
      return 'Eutrófica';
    } if (porcentagemPT >= 80 && porcentagemPT <= 90) {
      return 'Depleção leve';
    } if (porcentagemPT >= 70 && porcentagemPT <= 79) {
      return 'Depleção moderada';
    } if (porcentagemPT < 70) {
      return 'Depleção grave';
    }
    return 'Indeterminado';
  }

  // Função para calcular Circunferência Muscular do Braço (CMB)
  function calcularCMB(cb, pct) {
    const pi = Math.PI;
    if (!cb || !pct) {
      return 'N/A';
    }
    return (cb - (pi * pct)).toFixed(2);  // CMB = CB – (0,314 * PCT)
  }

  // Função para calcular Área Muscular do Braço (AMB)
  function calcularAMB(cb, pct) {
    if (!cb || !pct) {
      return 'N/A';
    }
    const cmb = calcularCMB(cb, pct);
    return (cmb * cmb / 4).toFixed(2);  // AMB = CMB² / 4
  }

  function calcularAMBc(cb, pct, gender) {
    const pi = Math.PI;
    const baseCalc = (cb - (pi * pct) / 10);
    if (gender === 'male') {
      return ((baseCalc * baseCalc) / (4 * pi) - 10).toFixed(2);
    }
    return ((baseCalc * baseCalc) / (4 * pi) - 6.5).toFixed(2);
  }

  function calcularAB(cb) {
    const pi = Math.PI;
    const d = cb * 10;
    return ((pi * d * d) / 4).toFixed(2);
  }

  function calcularAGB(ab, amb) {
    return (ab - amb).toFixed(2);
  }

  function classificarMusculatura(amb, gender) {
    if (gender === 'male') {
      if (amb <= 35) return 'Musculatura baixa';
      if (amb > 35 && amb <= 45) return 'Abaixo da média';
      if (amb > 45 && amb <= 60) return 'Média';
      if (amb > 60 && amb <= 75) return 'Acima da média';
      if (amb > 75) return 'Musculatura elevada';
    } else {
      if (amb <= 20) return 'Musculatura baixa';
      if (amb > 20 && amb <= 25) return 'Abaixo da média';
      if (amb > 25 && amb <= 35) return 'Média';
      if (amb > 35 && amb <= 55) return 'Acima da média';
      if (amb > 55) return 'Musculatura elevada';
    }
    return 'Indeterminado';
  }

  // Função para classificar o risco de doenças cardiovasculares com base na circunferência da cintura
  function classificarCircunferenciaCintura(waistCircumference, gender) {
    if (gender === 'male') {
      if (waistCircumference < 94) {
        return 'Sem risco';
      } if (waistCircumference >= 94 && waistCircumference <= 102) {
        return 'Risco Elevado';
      } if (waistCircumference > 102) {
        return 'Risco Muito Elevado';
      }
    } else if (gender === 'female') {
      if (waistCircumference < 80) {
        return 'Sem risco';
      } if (waistCircumference >= 80 && waistCircumference <= 88) {
        return 'Risco Elevado';
      } if (waistCircumference > 88) {
        return 'Risco Muito Elevado';
      }
    }
    return 'Indeterminado';
  }

  // Função para calcular a altura estimada (Chumlea et al., 1985)
  function calcularAlturaEstimada(idade, alturaJoelho, gender) {
    if (gender === 'male') {
      return (64.19 - (0.04 * idade) + (2.02 * alturaJoelho)).toFixed(2);
    } if (gender === 'female') {
      return (84.88 - (0.24 * idade) + (1.83 * alturaJoelho)).toFixed(2);
    }
    return 'Indeterminado';
  }

  // Função para calcular % de gordura corporal (Pollock & Jackson, 1978)
  function calcularPercentualGordura(gender, somaDobras, idade, tipoFormula) {
    let D = 0;

    if (gender === 'male') {
      if (tipoFormula === 'Σ3ToraxAbdominalCoxa') {
        D = 1.1093800 - 0.0008267 * somaDobras + 0.0000016 * somaDobras ** 2 - 0.0002574 * idade;
      } else if (tipoFormula === 'Σ3ToraxTricepsSubescapular') {
        D = 1.1125025 - 0.0013125 * somaDobras + 0.0000055 * somaDobras ** 2 - 0.0002440 * idade;
      } else if (tipoFormula === 'Σ7') {
        D = 1.11200000 - 0.00043499 * somaDobras + 0.00000055 * somaDobras ** 2 - 0.0002882 * idade;
      }
    } else if (gender === 'female') {
      if (tipoFormula === 'Σ3TricepsSuprailiacaCoxa') {
        D = 1.0994921 - 0.0009929 * somaDobras + 0.0000023 * somaDobras ** 2 - 0.0001392 * idade;
      } else if (tipoFormula === 'Σ3TricepsSuprailiacaAbdominal') {
        D = 1.089733 - 0.0009245 * somaDobras + 0.0000025 * somaDobras ** 2 - 0.0000979 * idade;
      } else if (tipoFormula === 'Σ7') {
        D = 1.0970 - 0.00046971 * somaDobras + 0.00000056 * somaDobras ** 2 - 0.00012828 * idade;
      }
    }

    const percentualGordura = ((4.95 / D) - 4.50) * 100;
    return percentualGordura.toFixed(2);
  }

  // Função para classificar IMC de acordo com a classificação de Lipschitz (1994)
  function classificarIMCIdosos(imc) {
    if (imc < 22) return "Baixo Peso";
    if (imc >= 22 && imc <= 27) return "Eutrofia";
    if (imc > 27) return "Sobrepeso";
    return "Indeterminado";
  }

  // Função para calcular a Idade Gestacional (IG) com base na DUM
  function calcularIdadeGestacional(dum) {
    const hoje = new Date();
    const dataDUM = new Date(dum);
    const diferencaTempo = hoje - dataDUM;
    const idadeGestacional = Math.floor(diferencaTempo / (1000 * 60 * 60 * 24 * 7)); // Convertendo para semanas
    return idadeGestacional;
  }

  // Função para calcular a Data Prevista do Parto (DPP)
  function calcularDPP(dum) {
    const dataDUM = new Date(dum);
    const dataDPP = new Date(dataDUM.setDate(dataDUM.getDate() + 280)); // Adicionando 280 dias (40 semanas)
    return dataDPP.toLocaleDateString();
  }

  // Função para classificar o IMC para crianças com base no WHO 2006/2007
  function classificarIMCCriancas(imcZscore) {
    if (imcZscore > 2) return 'Sobrepeso';
    if (imcZscore >= -2 && imcZscore <= 2) return 'Peso adequado';
    if (imcZscore < -2) return 'Baixo peso';
    return 'Indeterminado';
  }

  return (
    <>
      <h3>Buscar Medições Antropométricas por Paciente</h3>

      <div className="getAnthropometryForm">
        <div className="getAnthropometry-form-group">
          <h5 className='anthropometryTitle'>Paciente:</h5>
          <Select
            name="patient_id"
            options={patients.map(patient => ({
              value: patient.person_id,
              label: patient.name,
            }))}
            onChange={handlePatientChange}
            classNamePrefix="custom-select"
            value={selectedPatient} // Adicionar o valor do estado selecionado
            placeholder={!selectedPatient ? "Selecione o paciente" : ""} // Exibir o placeholder quando não há paciente selecionado
            isLoading={loadingPatients}
            isClearable // Permite limpar o valor selecionado e ver o placeholder
          />
          {errorPatients && <div className="error-message">Erro ao carregar pacientes: {errorPatients}</div>}
          <button type="button" className='returnDashboard' onClick={handleBack}
            data-tip={returnBtn}><MdHome /></button>
        </div>
      </div>
      <div className="getAccordion">
        {loadingAnthropometry ? (
          <div>Carregando medições...</div>
        ) : errorAnthropometry ? (
          <div className="error-message">Erro ao carregar medições antropométricas: {errorAnthropometry}</div>
        ) : currentAnthropometry.length === 0 && selectedPatient ? (
          <div>Nenhuma medição encontrada para o paciente selecionado.</div>
        ) : selectedPatient && (
          <div>
            <h5 className='anthropometryTitle'>Medições Anteriores</h5>
            <div className="row-getMeasures">
              {currentItems.map((anthropometry) => {
                const {
                  imc,
                  imcClassificacao,
                  quetelet,
                  pesoTeorico,
                  classificacaoBernadinelli,
                  porcentagemPT,
                  classificacaoBlackburn,
                  cmb,
                  amb,
                  ambc,
                  ab,
                  agb,
                  classificacaoMusculatura
                } = calcularMedidas(anthropometry);

                return (
                  <Accordion
                    key={anthropometry.measurement_date}
                    title={
                      <div className="row-getAnthropometryBox">
                        <div className="colAntBox">
                          <label htmlFor="date" className="labelAnt">Data</label>
                          <div className="rowAntBox">
                            <LuCalendarClock /> {formatDate(anthropometry.measurement_date)}
                          </div>
                        </div>
                        <div className="colAntBox">
                          <label htmlFor="date" className="labelAnt">Paciente</label>
                          <div className="rowAntBox">
                            <MdOutlineSick /> {selectedPatient ? selectedPatient.label : ''}
                          </div>
                        </div>
                        <div className="colAntBox">
                          <label htmlFor="date" className="labelAnt">Profissional</label>
                          <div className="rowAntBox">
                            <FaUserDoctor /> {findNutritionistName(anthropometry.nutritionist_id)}
                          </div>
                        </div>
                        <div className="colAntBox">
                          <label htmlFor="date" className="labelAnt">IMC</label>
                          <div className="rowAntBox">
                            {imc} |  <BiBody /> | {imcClassificacao}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div className="row-Anthropometry">
                      {selectedPatient && (
                        <>
                          <h5 className='anthropometryTitle'>Resultados da Avaliação</h5>
                          <div className="row-box">
                            <div className="row-line">
                              <label>Gênero</label>
                              <div className="return-data">{formatGender(patientGender)}</div>
                            </div>

                            <div className="row-line">
                              <label>Data de Nascimento</label>
                              <div className="return-data">{formatDate(patientBirthDate)}</div>
                            </div>

                            <div className="row-line">
                              <label>Idade</label>
                              <div className="return-data">{calculateAge(patientBirthDate)}</div>
                            </div>

                            <div className="row-line">
                              <label>IMC</label>
                              <div className="return-data">{imc} | {imcClassificacao}</div>
                            </div>

                            <div className="row-line">
                              <label>quetelet </label>
                              <div className="return-data">{quetelet}</div>
                            </div>

                            <div className="row-line">
                              <label>Peso Teórico (PT)</label>
                              <div className="return-data">{pesoTeorico.toFixed(2)} kg</div>
                            </div>

                            <div className="row-line">
                              <label>Classificação Biotipo (Bernadinelli)</label>
                              <div className="return-data">{classificacaoBernadinelli}</div>
                            </div>

                            <div className="row-line">
                              <label>% PT (Blackburn)</label>
                              <div className="return-data">{porcentagemPT.toFixed(2)}%</div>
                            </div>

                            <div className="row-line">
                              <label>Classificação Nutricional (Blackburn)</label>
                              <div className="return-data">{classificacaoBlackburn}</div>
                            </div>

                            <div className="row-line">
                              <label>CMB (Circunferência Muscular do Braço)</label>
                              <div className="return-data">{cmb} cm</div>
                            </div>

                            <div className="row-line">
                              <label>AMB (Área Muscular do Braço)</label>
                              <div className="return-data">{amb} cm²</div>
                            </div>

                            <div className="row-line">
                              <label>AMBc (Área Muscular do Braço Corrigida)</label>
                              <div className="return-data">{ambc} cm²</div>
                            </div>

                            <div className="row-line">
                              <label>AB (Área do Braço)</label>
                              <div className="return-data">{ab} mm²</div>
                            </div>

                            <div className="row-line">
                              <label>AGB (Área Gordurosa do Braço)</label>
                              <div className="return-data">{agb} mm²</div>
                            </div>

                            <div className="row-line">
                              <label>Classificação Musculatura</label>
                              <div className="return-data">{classificacaoMusculatura}</div>
                            </div>

                            <div className="row-line">
                              <label>Circunferência da Cintura</label>
                              <div className="return-data">{cintura ? `${cintura} cm | ${classificacaoCintura}` : 'N/A'}</div>
                            </div>
                            <div className="row-line">
                              <label>Altura Estimada</label>
                              <div className="return-data">{alturaEstimada ? `${alturaEstimada} cm` : 'N/A'}</div>
                            </div>

                          </div>
                        </>
                      )}
                    </div>

                    <Accordion title={"Medidas Essênciais"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor="measurement_date">Data da Medição</label>
                          <div>{formatDate(anthropometry.measurement_date)}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="height">Altura</label>
                          <div>{anthropometry.height} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="weight">Peso Atual</label>
                          <div>{anthropometry.current_weight} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="imcClassificacao">IMC</label>
                          <div>{imc} | {imcClassificacao ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="estimated_height">Altura Estimada</label>
                          <div>{anthropometry.estimated_height} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="estimated_current_weight">Peso Estimado Atual</label>
                          <div>{anthropometry.estimated_current_weight} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="desired_weight">Peso Desejado</label>
                          <div>{anthropometry.desired_weight} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="usual_weight">Peso Habitual</label>
                          <div>{anthropometry.usual_weight} kg</div>
                        </div>
                        <div className="colEmpty">
                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Braços"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='left_arm'>Braço Esquerdo</label>
                          <div>{anthropometry.left_arm ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_arm'>Braço Direito</label>
                          <div>{anthropometry.right_arm ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='left_forearm'>Antebraço Esquerdo</label>
                          <div>{anthropometry.left_forearm ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_forearm'>Antebraço Direito</label>
                          <div>{anthropometry.right_forearm ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='left_hand'>Mão Esquerda</label>
                          <div>{anthropometry.left_hand ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_hand'>Mão Direita</label>
                          <div>{anthropometry.right_hand ?? 'N/A'} cm</div>
                        </div>
                        <div className="colEmpty">
                        </div>
                        <div className="colEmpty">
                        </div>
                      </div>

                    </Accordion>

                    <Accordion title={"Pernas"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='left_leg'>Perna Esquerda</label>
                          <div>{anthropometry.left_leg ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_leg'>Perna Direita</label>
                          <div>{anthropometry.right_leg ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='left_lower_leg'>Perna Inferior Esquerda</label>
                          <div>{anthropometry.left_lower_leg ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_lower_leg'>Perna Inferior Direita</label>
                          <div>{anthropometry.right_lower_leg ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='left_foot'>Pé Esquerdo</label>
                          <div>{anthropometry.left_foot ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_foot'>Pé Direito</label>
                          <div>{anthropometry.right_foot ?? 'N/A'} cm</div>
                        </div>
                        <div className="colEmpty">

                        </div>
                        <div className="colEmpty">

                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Circunferências"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='head_circumference'>Circunferência da Cabeça</label>
                          <div>{anthropometry.head_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='arm_circumference'>Circunferência do Braço</label>
                          <div>{anthropometry.arm_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='waist_circumference'>Circunferência da Cintura</label>
                          <div>{anthropometry.waist_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='chest_circumference'>Circunferência do Peito</label>
                          <div>{anthropometry.chest_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='hip_circumference'>Circunferência do Quadril</label>
                          <div>{anthropometry.hip_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='gluteal_max_circumference'>Circunferência Máxima do Glúteo</label>
                          <div>{anthropometry.gluteal_max_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='thigh_max_circumference'>Circunferência Máxima da Coxa</label>
                          <div>{anthropometry.thigh_max_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='forearm_circumference'>Circunferência Máxima do Antebraço</label>
                          <div>{anthropometry.forearm_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='wrist_circumference'>Circunferência do Pulso</label>
                          <div>{anthropometry.wrist_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='calf_circumference'>Circunferência da Panturrilha</label>
                          <div>{anthropometry.calf_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='ankle_circumference'>Circunferência do Tornozelo</label>
                          <div>{anthropometry.ankle_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='abdominal_circumference'>Circunferência Abdominal</label>
                          <div>{anthropometry.abdominal_circumference ?? 'N/A'} cm</div>
                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Diâmetros e Alturas Osseas"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='knee_height'>Altura do Joelho</label>
                          <div>{anthropometry.knee_height ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='radial_diameter'>Diâmetro do Radial</label>
                          <div>{anthropometry.radial_diameter ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='femur_diameter'>Diâmetro do Fêmur</label>
                          <div>{anthropometry.femur_diameter ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='humeral_diameter'>Diâmetro do Úmero</label>
                          <div>{anthropometry.humeral_diameter ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='humeral_diameter'>Diâmetro do Úmero</label>
                          <div>{anthropometry.humeral_diameter ?? 'N/A'} cm</div>
                        </div>
                        <div className="colEmpty">

                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Dobras Cutâneas"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='measurement_type'>Tipo de Medição</label>
                          <div>{anthropometry.measurement_type ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='suprailiac_skinfold'>Dobra Suprailíaca</label>
                          <div>{anthropometry.suprailiac_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='subscapular_skinfold'>Dobra Subescapular</label>
                          <div>{anthropometry.subscapular_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='supraespinal_skinfold'>Dobra Supraespinhal</label>
                          <div>{anthropometry.supraespinal_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='chest_skinfold'>Dobra Peitoral</label>
                          <div>{anthropometry.chest_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='triceps_skinfold'>Dobra Trícepes</label>
                          <div>{anthropometry.triceps_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='biceps_skinfold'>Dobra Bícepes</label>
                          <div>{anthropometry.biceps_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='thigh_skinfold'>Dobra da Coxa</label>
                          <div>{anthropometry.thigh_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='calf_skinfold'>Dobra da Panturrilha</label>
                          <div>{anthropometry.calf_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='abdominal_skinfold'>Dobra Abdominal</label>
                          <div>{anthropometry.abdominal_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='axillary_skinfold'>Dobra Axilar</label>
                          <div>{anthropometry.axillary_skinfold ?? 'N/A'} cm</div>
                        </div>
                        <div className="colEmpty"></div>
                      </div>
                    </Accordion>

                    <Accordion title={"Fatores VET"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='vet_final'>VET Final</label>
                          <div>{anthropometry.vet_final ?? 'N/A'} kcal</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='vet_final_equation'>VET Final Equação</label>
                          <div>{anthropometry.vet_final_equation ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='vet_final_weight'>VET Final Peso</label>
                          <div>{anthropometry.vet_final_weight ?? 'N/A'} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='formula_vet_code'>Código da Fórmula VET</label>
                          <div>{anthropometry.formula_vet_code ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='activity_factor_code'>Código do Fator de Atividade</label>
                          <div>{anthropometry.activity_factor_code ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='formula_fat_percentage_code'>Código da Fórmula do Percentual de Gordura</label>
                          <div>{anthropometry.formula_fat_percentage_code ?? 'N/A'}</div>
                        </div>
                        <div className="colEmpty"></div>
                        <div className="colEmpty"></div>
                      </div>
                    </Accordion>

                    <Accordion title={"Classificações e Diagnósticos"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='pg_classification_code'>Código da Classificação PG</label>
                          <div>{anthropometry.pg_classification_code ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='formula_pt_code'>Código da Fórmula Peso Teórico - PT</label>
                          <div>{anthropometry.formula_pt_code ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='body_fat_percentage'>Percentual de Gordura Corporal</label>
                          <div>{anthropometry.body_fat_percentage ?? 'N/A'} %</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='ideal_fat_percentage'>Percentual Ideal de Gordura</label>
                          <div>{anthropometry.ideal_fat_percentage ?? 'N/A'} %</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='gi_percentage_type'>Tipo de Percentual GI</label>
                          <div>{anthropometry.gi_percentage_type ?? 'N/A'} %</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='total_body_water'>Total Corporal de Água</label>
                          <div>{anthropometry.total_body_water ?? 'N/A'} %</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='lean_weight_type'>Tipo de Peso Magro</label>
                          <div>{anthropometry.lean_weight_type ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='muscle_mass_weight'>Peso da Massa Corporal</label>
                          <div>{anthropometry.muscle_mass_weight ?? 'N/A'} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='physical_activity'>Atividade Física</label>
                          <div>{anthropometry.physical_activity ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='physical_activity_level'>Nível da Atividade Física</label>
                          <div>{anthropometry.physical_activity_level ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='diagnosis'>Diagnóstico</label>
                          <div>{anthropometry.diagnosis ?? 'N/A'}</div>
                        </div>
                        <div className="colEmpty"></div>
                      </div>
                    </Accordion>

                    <Accordion title={"Outros Índices"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='age_years'>Idade / Anos</label>
                          <div>{anthropometry.age_years ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='age_months'>Idade / Meses</label>
                          <div>{anthropometry.age_months ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='reference_estimated_weight'>Peso de Referência Esimado</label>
                          <div>{anthropometry.reference_estimated_weight ?? 'N/A'} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='fat_mass_weight'>Peso da Massa Gorda</label>
                          <div>{anthropometry.fat_mass_weight ?? 'N/A'} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='ppr_time'>Perda de Peso Recente - PPR</label>
                          <div>{anthropometry.ppr_time ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='vet_id'>VET ID</label>
                          <div>{anthropometry.vet_id ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='vet_reduction_id'>VET Redução ID</label>
                          <div>{anthropometry.vet_reduction_id ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='bray_classification'>Código de Zunido</label>
                          <div>{anthropometry.bray_classification ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='mcm'>MCM</label>
                          <div>{anthropometry.mcm ?? 'N/A'}</div>
                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Resultado da Avaliação"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor="gender">Gênero</label>
                          <div>{formatGender(patientGender)}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="gender">Data de Nascimento</label>
                          <div>{formatDate(patientBirthDate)}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="gender">Idade</label>
                          <div>{calculateAge(patientBirthDate)}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="imc_classification">IMC Classificação</label>
                          <div>{imc} | {imcClassificacao}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="quetelet ">quetelet </label>
                          <div>{quetelet}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="theoretical_weight">Peso Teórico</label>
                          <div>{pesoTeorico.toFixed(2)} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="classificacaoBernadinelli">Classificação Biotipo - Bernadinelli</label>
                          <div>{classificacaoBernadinelli}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="porcentagemPT">Percentual PT - Blackburn</label>
                          <div>{porcentagemPT.toFixed(2)} %</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="classificacaoBlackburn">Classificação Nutricional - Blackburn</label>
                          <div>{classificacaoBlackburn}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="cmb_arm">Circunferência Muscular do Braço</label>
                          <div>{cmb} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="amb_arm">Área Muscular do Braço</label>
                          <div>{amb} cm²</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="ambc_arm">Área Muscular do Braço Corrigido</label>
                          <div>{ambc} cm²</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="arm_area">Área do Braço</label>
                          <div>{ab} mm²</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="agb">Área Gordurosa do Braço</label>
                          <div>{agb} mm²</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="amb_arm">Classificação da Musculatura</label>
                          <div>{classificacaoMusculatura}</div>
                        </div>
                        <div className="colAnt">
                          <label>Circunferência da Cintura</label>
                          <div>{cintura ? `${cintura} cm | ${classificacaoCintura}` : 'N/A'}</div>
                        </div>
                        <div className="colEmpty">
                        </div>
                      </div>
                    </Accordion>

                  </Accordion>
                );
              })}
            </div>
          </div>
        )}

        {currentAnthropometry.length > 0 && (
          <Pagination
            totalItems={currentAnthropometry.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            paginate={paginate}
          />
        )}
      </div>
    </>
  );
}

export default GetAnthropometryByPatientId;
